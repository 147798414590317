<template>
  <div class="purchase-detail">
    <el-breadcrumb separator="/" class="student-breadcrumb">
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>在售商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-title">商品详情</div>
    <div style="padding: 20px; display: flex; align-items: center; background: #fff; margin-bottom: 20px">
      <img :src="goodsInfo.img" style="width: 58px; height: 58px; margin-right: 20px" />
      <div>{{ goodsInfo.goods_name }}</div>
    </div>
    <div style="padding: 20px; background: #fff; font-size: 16px">
      <div style="margin-bottom: 20px">商品概况</div>
      <div style="height: 112px; border: 1px solid #e5e5e5; display: flex">
        <div style="flex: 1; display: flex">
          <div style="width: 112px; background: #f8f8f8; display: flex; flex-direction: column; justify-content: center; align-items: center">
            <img src="../../assets/images/good-detail-yinliu.png" style="width: 42px; height: 42px; margin-bottom: 2px" />
            <div style="font-size: 14px">引流漏洞</div>
          </div>
          <div style="flex: 1; display: flex; flex-direction: column; justify-content: center; padding-left: 20px">
            <div>浏览量</div>
            <div style="margin-top: 20px; font-size: 18px">{{ goodsInfo.all_searcher }}</div>
          </div>
          <div style="flex: 1; display: flex; flex-direction: column; justify-content: center; padding-left: 20px">
            <div>成交件数指数</div>
            <div style="margin-top: 20px; font-size: 18px">{{ goodsInfo.all_conversion_num }}</div>
          </div>
          <div style="flex: 1; display: flex; flex-direction: column; justify-content: center; padding-left: 20px">
            <div>点击指数</div>
            <div style="margin-top: 20px; font-size: 18px">{{ goodsInfo.all_click_num }}</div>
          </div>
        </div>
        <div style="flex: 1; display: flex">
          <div style="width: 112px; background: #f8f8f8; display: flex; flex-direction: column; justify-content: center; align-items: center">
            <img src="../../assets/images/good-detail-zhuanhua.png" style="width: 42px; height: 42px; margin-bottom: 2px" />
            <div style="font-size: 14px">成交转化</div>
          </div>
          <div style="flex: 1; display: flex; flex-direction: column; justify-content: center; padding-left: 20px">
            <div>成交金额指数</div>
            <div style="margin-top: 20px; font-size: 18px">{{ goodsInfo.all_transaction_amount }}</div>
          </div>
          <div style="flex: 1; display: flex; flex-direction: column; justify-content: center; padding-left: 20px">
            <div>成交单量指数</div>
            <div style="margin-top: 20px; font-size: 18px">{{ goodsInfo.all_transaction_volume }}</div>
          </div>
          <div style="flex: 1; display: flex; flex-direction: column; justify-content: center; padding-left: 20px">
            <div>成交转化率</div>
            <div style="margin-top: 20px; font-size: 18px">{{ goodsInfo.all_transaction_conversion }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tranPurchaseBalanceTopDetail } from "@/utils/apis";

export default {
  name: "PurchaseDetail",
  components: {},
  props: {},
  data() {
    return {
      goodsInfo: {},
    };
  },
  computed: {},
  methods: {
    getDetails() {
      tranPurchaseBalanceTopDetail({ goods_id: this.$route.query.id }).then((res) => {
        this.goodsInfo = res.data;
      });
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped lang="scss">
.purchase-detail {
  box-sizing: border-box;
  height: 100%;
  padding: 40px;
  background: #f7f9fb;
  .page-title {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333;
    margin: 20px 0;
    line-height: 1;
  }
}
</style>
